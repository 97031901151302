import { IsInt, IsNotEmpty, IsOptional } from 'class-validator';
import { FormModes } from 'src/app/shared/form-modes.enum';
import { Calculation } from './calculation';
import { EntityDates } from './entity-dates';
import { Lot } from './lot';
import { Role } from './role';


export class User extends EntityDates {
	@IsInt()
	id!: number;
	@IsNotEmpty({groups: [FormModes.PATCH, FormModes.POST]})
	firstName: string;
	@IsNotEmpty({groups: [FormModes.PATCH, FormModes.POST]})
	lastName: string;
	get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}
	email: string;
	password!: string;
	role: Role;
	@IsOptional({groups: [FormModes.PATCH, FormModes.POST]})
	commissionCalculation?: Calculation;
	@IsOptional({groups: [FormModes.PATCH, FormModes.POST]})
	defaultLot?: Lot;
	@IsOptional({groups: [FormModes.PATCH, FormModes.POST]})
	extPayeeID?: string;

	public static fromApi(data: any): User {
		const retVal = new User();
		retVal.id = data.id;
		retVal.firstName = data.firstName;
		retVal.lastName = data.lastName;
		retVal.email = data.email;
		retVal.password = data.password;
		retVal.role = data.role;
		retVal.commissionCalculation = data.commissionCalculation;
		retVal.defaultLot = data.defaultLot;
		retVal.extPayeeID = data.extPayeeID;

		if (retVal.role) retVal.role = Object.assign(new Role(), retVal.role);
		if (retVal.commissionCalculation) retVal.commissionCalculation = Object.assign(new Calculation(), retVal.commissionCalculation);
		if (retVal.defaultLot) retVal.defaultLot = Object.assign(new Lot(), retVal.defaultLot);

		return retVal;
	}
}

Object.defineProperty(User, 'name', {value: 'Salesman'});
Object.defineProperty(User, 'plural', {value: 'Salesmen'});
