import { SharedModule } from 'src/app/shared/shared.module';
import { LoginComponent } from 'src/app/unauthenticated/login/login.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UnauthenticatedRoutingModule } from './unauthenticated-routing.module';
import { UnauthenticatedComponent } from './unauthenticated.component';

@NgModule({
	declarations: [
		UnauthenticatedComponent,
		LoginComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		UnauthenticatedRoutingModule
	]
})
export class UnauthenticatedModule { }
