import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APISubject } from 'src/app/shared/Utilities/observable.utilities';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../shared/entities/user';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		private http: HttpClient,
		private router: Router
	) { }

	public currentUser$ = new APISubject<User>(this.http.get<User>(`/users/self`));

	public fullName$ = this.currentUser$.pipe(map(u => u && `${u.firstName} ${u.lastName}`));

	public login(creds: {email: string, password: string}): Observable<User> {
		return this.http.post<User>(`/users/authorize`, creds).pipe(
			map(u => Object.assign(new User(), u)),
			tap(u => this.currentUser$.next(u))
		);
	}

	public logout(): Observable<void> {
		return this.http.post<void>(`/users/deauthorize`, undefined).pipe(
			tap(() => this.clearSession())
		);
	}

	public clearSession(): void {
		this.currentUser$.next(undefined);
		this.router.navigate(['login']);
	}
}
