import { IsArray, IsInt, IsNotEmpty } from 'class-validator';
import { FormModes } from 'src/app/shared/form-modes.enum';
import { Comparators } from './calculation-condition';
import { CalculationThreshold } from './calculation-threshold';


export class Calculation {
	private _sorted = false;

	@IsInt({groups: [FormModes.PATCH]})
	id!: number;
	@IsNotEmpty({groups: [FormModes.PATCH, FormModes.POST]})
	name: string;
	@IsArray({groups: [FormModes.PATCH, FormModes.POST]})
	thresholds: CalculationThreshold[] = [];

	calculate(source: {[key: string]: any}, sourceVal: number): number {
		if(!this._sorted) this.sort();
		if (!this.thresholds) return NaN;
		const targetThreshold = this.thresholds.find(t => {
			return t.conditions.every(({field, comparator, value}) => this.compare(source, field, comparator, value));
		});
		if (!targetThreshold) return NaN;
		switch (targetThreshold.calculationType) {
			case 'percentage':
				return sourceVal * (targetThreshold.value / 100);
			case 'flat':
				return targetThreshold.value;
			default:
				return NaN;
		}
	}

	private compare(source: {[key: string]: any}, propKey: string, comparator: Comparators, value: string): boolean {
		const targetType = Reflect.getMetadata('design:type', source, propKey);
		let parsedVal = targetType && targetType !== String ? targetType(value) : value;
		if (targetType === Number && isNaN(parsedVal)) parsedVal = 0;
		switch (comparator) {
			case Comparators.EQ:
				return source[propKey] === parsedVal;
			case Comparators.NE:
				return source[propKey] !== parsedVal;
			case Comparators.LT:
				return source[propKey] < parsedVal;
			case Comparators.GT:
				return source[propKey] > parsedVal;
			case Comparators.LTE:
				return source[propKey] <= parsedVal;
			case Comparators.GTE:
				return source[propKey] >= parsedVal;
			default:
				return false;
		}
	}

	private sort() {
		if(this.thresholds) this.thresholds.sort((t1, t2) => t2.priority - t1.priority)
		this._sorted = true;
	}
}
