import { IsInt, IsNotEmpty, IsOptional } from 'class-validator';
import { FormModes } from 'src/app/shared/form-modes.enum';
import { Calculation } from './calculation';
import { EntityDates } from './entity-dates';


export class Lot extends EntityDates {
	@IsInt({groups: [FormModes.PATCH]})
	id!: number;

	@IsNotEmpty({groups: [FormModes.PATCH, FormModes.POST]})
	name: string;

	@IsOptional({groups: [FormModes.PATCH, FormModes.POST]}	)
	public incomeCalculation?: Calculation;
	
	@IsOptional({groups: [FormModes.PATCH, FormModes.POST]}	)
	extAccountID: string;

	public static fromApi(data: any): Lot {
		const retVal = new Lot();
		retVal.id = data.id;
		retVal.name = data.name;
		retVal.incomeCalculation = data.incomeCalculation;
		retVal.extAccountID = data.extAccountID;

		if (retVal.incomeCalculation) retVal.incomeCalculation = Object.assign(new Calculation(), retVal.incomeCalculation);

		return retVal;
	}
}
