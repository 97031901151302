import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/_services/auth.service';

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

	public formGroup: FormGroup;

	constructor(
		private authSvc: AuthService,
		private router: Router,
		private messageService: MessageService
	) {
		this.formGroup = new FormGroup({
			email: new FormControl(null),
			password: new FormControl(null),
		});
	}

	login(form: FormGroup): void {
		this.authSvc.login(form.value).subscribe(
			(user) => {
				this.router.navigate(['']);
			},
			(e) => {
				if(e.status === 401) {
					this.messageService.add({
						severity: 'error',
						summary: 'Login Failed!',
						detail: 'Username or password was incorrect'
					})
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Login Failed!',
						detail: 'Unkown error'
					})
				}
				console.error(e);
			}
		);
	}

}
