import { from, ObjectUnsubscribedError, Observable, Subject, Subscriber, Subscription } from 'rxjs';

export class APISubject<T> extends Subject<T> {
	private _uninitialized = Symbol('uninitialized');
	private _initilizing = Symbol('initializing');

	private _value: T | symbol = this._uninitialized;

	constructor(private _startWith: Observable<T> | (() => Observable<T>)) {
		super();
	}

	get value(): T {
		return this.getValue();
	}

	public _subscribe(subscriber: Subscriber<T>): Subscription {
		const subscription = super._subscribe(subscriber);
		!subscription.closed && this._value !== this._uninitialized && this._value !== this._initilizing && subscriber.next(this.value);

		if (this._value === this._uninitialized) {
			this._value = this._initilizing;
			this.refresh();
		}

		return subscription;
	}

	getValue(): T {
		const { hasError, thrownError, _value } = this;
		if (hasError) throw thrownError;
		this._throwIfClosed();
		return [this._uninitialized, this._initilizing].includes(<symbol> _value) ? undefined : <T> _value;
	}

	refresh(): void {
		from((typeof this._startWith === 'function' ? this._startWith() : this._startWith).toPromise()).subscribe(this.next.bind(this));
	}

	protected _throwIfClosed(): void {
		if (this.closed) throw new ObjectUnsubscribedError();
	}

	next(value: T): void {
		super.next((this._value = value));
	}
}
