import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

	transform(value: any[], order = '', column: string = ''): any[] {
		if (!value || order === '' || !order) { return value; } // no array

		if (value.length <= 1) { return value; } // array with only one item

		return value.sort((i1, i2) => {
			const compA = column ? this.deepGet(i1, column) : i1;
			const compB = column ? this.deepGet(i2, column) : i2;
			let retVal = 0;

			if (compA < compB) retVal = 1;
			if (compA > compB) retVal = -1;
			if (order === 'asc') retVal *= -1;

			return retVal;
		});
	}

	private deepGet(v: any, cols: string): any {
		const retVal = v;
		return cols.split('.').reduce((r, k) => r ? r[k] : undefined, retVal);
	}
}
