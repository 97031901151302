import { CalculationThreshold } from './calculation-threshold';
import { EntityDates } from './entity-dates';
export class CalculationCondition extends EntityDates {
	id!: number;
	field: string;
	comparator: Comparators = Comparators.EQ;
	value: string;
	threshold: CalculationThreshold;
}

export enum Comparators {
	EQ = '=',
	NE = '!=',
	LT = '<',
	LTE = '<=',
	GT = '>',
	GTE = '>='
}
