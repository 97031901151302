import { Observable } from 'rxjs';
import { AuthGuard } from 'src/app/_guards/auth.guard';
import { AuthService } from 'src/app/_services/auth.service';
import { AuthenticatedComponent } from 'src/app/authenticated/authenticated.component';
import { User } from 'src/app/shared/entities/user';

import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { children } from './unauthenticated/unauthenticated-routing.module';

class CurrentUserResolver implements Resolve<User> {
	constructor(private authSvc: AuthService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): User | Observable<User> | Promise<User> {
		return this.authSvc.currentUser$;
	}
}

const routes: Routes = [{
	matcher: (url) => url.length && children.map(r => r.path.toLowerCase()).includes(url[0].path.toLowerCase()) ? null : {consumed: []},
	component: AuthenticatedComponent,
	canLoad: [AuthGuard],
	loadChildren: () => import('./authenticated/authenticated.module').then(m => m.AuthenticatedModule)
}];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
