import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	@Input()
	popup = false;
	
	@HostBinding('class')
	public class: string = 'p-d-none p-d-md-flex'

	@Output()
	linkClicked = new EventEmitter<string>();

	constructor(
		public router: Router
	) {}

	ngOnInit() {
		if(this.popup) this.class = 'p-d-flex'
	}

	public buttons = [{
		label: 'Sales',
		route: '/sales',
	}, {
		label: 'Payroll',
		route: '/payroll',
	}, {
		label: 'Settings',
		route: '/settings',
	}];

	public getClass$ = (route: string) => this.router.events.pipe(
		startWith(new NavigationStart(undefined, this.router.url)),
		filter(event => event instanceof NavigationStart),
		map((event: NavigationStart) => event.url === route ? '' : 'p-button-secondary p-button-text'),
		map(c => `p-button-lg p-mb-2${c && ' ' + c}`)
	)
}
