import { Component } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

	public sidebarShown = false;

	public items = [{
		label: 'Log Out',
		icon: 'pi pi-sign-out',
		command: () => {
			this.authSvc.logout().subscribe();
		}
	}];
	constructor(public authSvc: AuthService) { }
}
