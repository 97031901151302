import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor(private authSvc: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.startsWith('/')) {
			req = req.clone({
				url: `${environment.apiBase}${req.url}`,
				withCredentials: true,
			});
			return next.handle(req).pipe(
				map(e => {
					if (e instanceof HttpResponse) {
						const data = e.body.data
						if(typeof e.body === 'object' && typeof data === 'object') Reflect.defineMetadata('meta:pagination', e.body.pagination, data)
						return e.clone({ body: data });
					}
					return e;
				}),
				catchError((e: HttpErrorResponse) => {
					if (e instanceof HttpErrorResponse && e.status === 401) {
						this.authSvc.clearSession();
					}
					return throwError(e);
				})
			);
		}
		return next.handle(req);
	}
}
