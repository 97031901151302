import { EntityDates } from './entity-dates';
import { User } from './user';

export class Role extends EntityDates {
	slug: string;
	name: string;
	users: User[] = [];

	constructor(name?: string) {
		super();
		if (name) this.name = name;
		if (!this.slug && this.name) this.slug = this.name.toLowerCase().split(/[\s,_]+/).join('-');
	}
}
