import { AuthService } from 'src/app/_services/auth.service';

import { Component } from '@angular/core';

@Component({
	selector: 'app-authenticated',
	templateUrl: './authenticated.component.html',
	styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent {

	constructor(
		public authSvc: AuthService
	) { }

}
