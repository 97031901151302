import { PrimeNGConfig } from 'primeng/api';

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(
		private primengConfig: PrimeNGConfig,
	) {}

	ngOnInit(): void {
		this.primengConfig.ripple = true;
	}
}
