import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SortByPipe } from 'src/app/shared/pipes/sort-by.pipe';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgLetDirective } from './utilities/let.directive';

const imports = [
	ButtonModule,
	MenuModule,
	InputTextModule,
	InputTextareaModule,
	TriStateCheckboxModule,
	FormsModule,
	ReactiveFormsModule,
	ToastModule
];
const declarations = [ SortByPipe, NgLetDirective ];

@NgModule({ imports, declarations, exports: [...imports, ...declarations], providers: [MessageService] })
export class SharedModule { }
