import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from 'src/app/_services/auth.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

	constructor(private authSvc: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authSvc.currentUser$.pipe(first(), map(t => !!t || this.router.parseUrl('/login')));
	}
	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authSvc.currentUser$.pipe(first(), map(t => !!t || this.router.parseUrl('/login')));
	}
	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree		> {
		return this.authSvc.currentUser$.pipe(first(), map(t => !!t || this.router.parseUrl('/login')));
	}
}
