import { LoginComponent } from 'src/app/unauthenticated/login/login.component';
import { UnauthenticatedComponent } from 'src/app/unauthenticated/unauthenticated.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const children: Routes = [
	{path: 'login', component: LoginComponent}
];

@NgModule({
	imports: [RouterModule.forChild([{
		path: '',
		component: UnauthenticatedComponent,
		children
	}])],
	exports: [RouterModule]
})
export class UnauthenticatedRoutingModule { }
