import '@angular/compiler';

import { ApiInterceptor } from 'src/app/_interceptors/api.interceptor';
import { SharedModule } from 'src/app/shared/shared.module';
import { UnauthenticatedRoutingModule } from 'src/app/unauthenticated/unauthenticated-routing.module';
import { UnauthenticatedModule } from 'src/app/unauthenticated/unauthenticated.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		UnauthenticatedRoutingModule,
		UnauthenticatedModule,
		SharedModule,
		HttpClientModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
