<div class="header-wrapper p-d-flex p-flex-row p-jc-md-end p-jc-between">
  <div class="p-d-md-none p-d-flex p-flex-row">
    <button pButton class="p-button-rounded p-button-text p-d-flex" icon="pi pi-bars" (click)="sidebarShown = true"></button>
    <img src="assets/img/logo/WKAlphaFull-300x99.png" id="logo-img" class="p-d-flex"/>
  </div>
  <button id="user-button" pButton pRipple label="{{authSvc.fullName$ | async}}" icon="pi pi-user" iconPos="left" class="p-button-text p-button-plain" (click)="menu.toggle($event)"></button>
</div>

<p-menu #menu [popup]="true" [model]="items"></p-menu>
<p-sidebar class="p-d-md-none p-d-flex" [(visible)]="sidebarShown">
  <app-sidebar class="p-col-fixed" [popup]="true" (linkClicked)="sidebarShown = false"></app-sidebar>
</p-sidebar>